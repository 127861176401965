var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"top-bar"}},[_c('v-app-bar',{staticClass:"py-2",attrs:{"app":"","color":"app-body","flat":"","height":"100"}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","xl":"12"}},[_c('v-sheet',{staticClass:"px-3 py-2 custom-border border d-flex justify-space-evenly",class:_vm.$vuetify.breakpoint.mobile &&
                        _vm.$route.name === 'Instructor Manage Course'
                            ? 'flex-column'
                            : 'flex-row align-center '},[_c('section',{staticClass:"d-flex flex-row align-center"},[_c('v-btn',{staticClass:"hidden-lg-and-up",attrs:{"icon":"","dense":""},on:{"click":function($event){return _vm.$emit('showDrawer')}}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-menu")])],1),(
                                _vm.$route.name === 'Instructor Manage Course'
                            )?_c('section',{staticClass:"d-flex align-center poppins secondary-1--text fw600"},[(
                                    !_vm.$isReadOnly.isReadOnly(
                                        _vm.course.status,
                                        _vm.course.my_permission.length > 0
                                            ? _vm.course.my_permission[0].pivot
                                                  .permission
                                            : 'owner',
                                    )
                                )?_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","dense":"","small":""},on:{"click":function($event){_vm.onEdit = !_vm.onEdit}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1):_vm._e(),(_vm.onEdit)?_c('v-text-field',{staticClass:"poppins f14 secondary-1--text fw600",attrs:{"hide-details":""},on:{"input":_vm.setTitle},model:{value:(_vm.course.title),callback:function ($$v) {_vm.$set(_vm.course, "title", $$v)},expression:"course.title"}}):_vm._e(),(!_vm.onEdit)?_c('span',{staticClass:"ellipsis",staticStyle:{"max-width":"70vw !important"}},[_vm._v(" "+_vm._s(_vm.course.title)+" ")]):_vm._e()],1):(_vm.$route.name === 'Instructor Grading')?_c('section',{staticClass:"d-flex align-center poppins secondary-1--text fw600"},[_vm._v(" Grading ")]):_c('section',{staticClass:"d-flex align-center poppins secondary-1--text fw600"},[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v(" "+_vm._s(`Hello, ${_vm.user.first_name ? _vm.user.first_name : "Instructor"} ${_vm.user.last_name ? _vm.user.last_name : ""}`)+" ")])]),_c('v-spacer')],1),_c('v-spacer'),(_vm.$route.name != 'Instructor Manage Course')?_c('div',{staticClass:"d-flex align-center"},[_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","color":"primary","dense":""}},'v-btn',attrs,false),on),[(
                                            _vm.notification.every(
                                                (notif) =>
                                                    notif.read_at != null,
                                            )
                                        )?_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-bell-outline")]):_c('v-badge',{attrs:{"color":"pink","dot":"","dense":""}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-bell-outline")])],1)],1)]}}],null,false,1211221783)},[_c('NotificationList',{attrs:{"notification":_vm.notification,"role":_vm.role},on:{"readMessage":_vm.readMessage,"markAll":_vm.markAll}})],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{staticClass:"text-capitalize poppins fw600 f12 small",attrs:{"icon":"","color":"primary","dense":""},on:{"click":function($event){return _vm.$emit('add')}}},[_c('v-icon',[_vm._v("mdi-book-plus-outline")])],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{staticClass:"text-capitalize poppins fw600 f12 small mr-1",attrs:{"icon":"","color":"primary","dense":""},on:{"click":function($event){return _vm.$emit('enroll')}}},[_c('v-icon',[_vm._v("mdi-account-plus")])],1):_vm._e()],1):_vm._e(),(
                            _vm.$route.name == 'Instructor Manage Course'
                                ? _vm.$isReadOnly.showCardAction(
                                      _vm.course.status,
                                      _vm.course.my_permission.length > 0
                                          ? _vm.course.my_permission[0].pivot
                                                .permission
                                          : 'owner',
                                  )
                                : false
                        )?_c('div',{class:!_vm.$vuetify.breakpoint.mobile && 'mx-2'},[_c('div',{staticClass:"f10 poppins text-center secondary--text"},[_vm._v(" COURSE STATUS ")]),_c('v-btn-toggle',{staticClass:"rounded-md d-flex flex-row mb-2",attrs:{"dense":""}},[_c('v-btn',{staticClass:"poppins f12 text-capitalize flex-grow-1",attrs:{"outlined":""},on:{"click":function($event){return _vm.draft()}}},[_vm._v(" "+_vm._s(_vm.course.status === "DRAFT" ? "Course Drafted " : "Save to Drafts")+" ")]),_c('v-btn',{staticClass:"poppins f12 text-capitalize flex-grow-1",attrs:{"color":"primary","dark":"","width":"130"},on:{"click":function($event){return _vm.publish()}}},[_vm._v(" "+_vm._s(_vm.course.status === "PUBLISH" ? "Unpublish" : "Publish")+" ")])],1)],1):_vm._e(),(_vm.$route.name !== 'Instructor Manage Course')?_c('v-avatar',{attrs:{"size":"30"}},[_c('img',{attrs:{"alt":"Avatar","src":_vm.user.image
                                    ? _vm.user.image.url
                                    : require('../../assets/default-photo.png'),"lazy-src":require('../../assets/default-photo.png')},on:{"error":function($event){_vm.user.image = null}}})]):_vm._e(),_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
                                    _vm.$route.name !==
                                    'Instructor Manage Course'
                                )?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1):_vm._e()]}}])},[_c('CommonToolsVue',{attrs:{"role":'instructor'}})],1)],1)],1)],1),(_vm.alert.show)?_c('toaster',{attrs:{"show":_vm.alert.show,"text":_vm.alert.text,"type":_vm.alert.type},on:{"close":function($event){_vm.alert.show = false}}}):_vm._e()],1),_c('DraftCourse',{attrs:{"dialog":_vm.dialogDraft,"course":_vm.course},on:{"close":function($event){_vm.dialogDraft = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }